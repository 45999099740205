import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Project Manager",
      desc: "As a PM I lead projects from requirements definition through deployment, identifying schedules, scopes, budget estimations and project implementation plans, including risk mitigation. My areas of expertise are e-commerce, content and digital marketing.",
      icon: "fa-solid fa-chart-line",
    },
    {
      name: "Product Owner",
      desc: "As a PO I drive product vision, backlog management, requirements gathering, sprint planning, acceptance, and collaboration between stakeholders and the development team, ensuring the successful delivery of customer-centric products that meet business objectives and user needs.",
      icon: "fa-solid fa-user-gear",
    },
    {
      name: "Agile Software Delivery (Scrum (Master))",
      desc: "As a Agile DM / SM I facilitate and coach to ensure that Scrum is effectively implemented. I guide the team in self-organization, remove impediments, and promote collaboration and continuous improvement. This is executed through the facilitation of ceremonies, manage the team's workflow, and foster an environment that encourages transparency, accountability, and delivery of value.",
      icon: "fa-solid fa-cubes-stacked",
    },
    {
      name: "Business Analyst",
      desc: "As a BA I analyze business processes, gather requirements, and translates them into user stories and acceptance criteria. In addition, I facilitate the communication between stakeholders and the development team, ensuring achievement of the successful delivery of high-quality products that meet both business goals and customer needs.",
      icon: "fa-solid fa-magnifying-glass-chart",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
